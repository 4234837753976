.upcoming-event-horizontal{
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1rem 1rem;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    border-radius: 8px;
    transition: 0.4s;
}
.upcoming-event-horizontal:hover{
    background-color: var(--purple);
    color: white;
}
.upcoming-event-horizontal img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.edit-delete-box{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.edit-delete-box .edit-icon{
    color: var(--blue);
    cursor: pointer;
}
.edit-delete-box .delete-icon{
    color: var(--red);
    cursor: pointer;
}


@media screen and (max-width:750px) {
    .event-date-h5{
        display: none;
    }
}
@media screen and (max-width:550px) {
    .upcoming-event-horizontal h5{
        font-size: 10px;
    }
}
@media screen and (max-width:420px) {
    .upcoming-event-horizontal h5{
        font-size: 7px;
    }
    .upcoming-event-horizontal img{
        width: 20px;
        height: 20px;
    }
    .edit-delete-box .edit-icon , .edit-delete-box .delete-icon{
        font-size: 14px;
    }
}