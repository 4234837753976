.goals-achieved-section{
    margin-top: 3rem;
}
.goals-achieved-section .right-side{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 500px;
}
.goals-achieved-section .right-side p{
    font-size: 12px;
    margin-top: 15px;
}

.goals-achieved-section .left-side{
    display: flex;
    justify-content: center;
    align-items: center;
}
.goals-achieved-section .left-side img{
    width: 400px;
}

@media screen and (max-width:1000px){
    .goals-achieved-section .right-side{
        max-width: 400px;
    }
    .goals-achieved-section .right-side p{
        font-size: 10px;
        max-width: 350px;
    }
    .goals-achieved-section .right-side h3{
        font-size: 20px;
    }
    .goals-achieved-section .left-side img{
        width: 250px;
    }
}
@media screen and (max-width:740px){
    .goals-achieved-section .right-side{
        width: 100%;
        max-width: 500px;
        text-align: center;
    }
    .goals-achieved-section .common-underline{
        width: 100%;
    }
    .goals-achieved-section .right-side p{
        font-size: 10px;
        width: 100%;
        max-width: 500px;
    }
    .goals-achieved-section .right-side h3{
        font-size: 20px;
    }
    .goals-achieved-section .left-side img{
        width: 270px;
    }
}

@media screen and (max-width:400px) {
    .goals-achieved-section .left-side img{
        width: 250px;
    }
}