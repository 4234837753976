.previous-event-main-card{
    padding: 1rem 1rem;
    background-color: var(--gray);
    border-radius: 8px;
    width: 350px;
    height: 350px;
    transition: 0.6s;
}
.previous-event-main-card:hover{
    background-color: var(--blue);
    color: white;
}
.previous-event-main-card-bottom{
    margin-top: 2rem;
}
.previous-event-main-card-bottom button{
    background-color: var(--red);
    border-radius: 8px;
    width: 100%;
}
.previous-event-main-card-top-upper-part{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 2rem;
}
.previous-event-main-card-top-upper-part-left img{
    width: 150px;
    height: 100px;
    object-fit: cover;
}
.previous-event-main-card-top-bottom-part p{
    font-size: 10px;
}
.previous-event-main-card-top{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.previous-event-main-card-top-upper-part-right{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
