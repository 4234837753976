.event-page-landing-section{
    width: 100%;
    height: 100vh;
    padding: 5rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    background: url("../../images/event\ landing\ section\ bg.png");
    background-size: cover;
    background-position: center;
    color: white;
    position: relative;
}
.event-page-landing-section::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #8d00b4bd;
}
.event-page-landing-section h1{
    position: relative;
    max-width: 800px;
    text-transform: uppercase;
}
.event-page-landing-section p{
    position: relative;
    max-width: 600px;
}
.event-page-why-join-section{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap-reverse;
    margin-top: 3rem;
}
.event-page-why-join-section-right{
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.event-page-why-join-section-right h2{
    text-transform: uppercase;
}
.event-page-why-join-section-left{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 3rem;
    column-gap: 4rem;
}
.event-page-why-join-section-left-icon img{
    width: 80px;
}


@media screen and (max-width:750px) {
    .event-page-landing-section h1{
        font-size: 3rem;
        max-width: 600px;
    }
    .event-page-why-join-section-right{
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width : 950px) {
    .event-page-why-join-section-right h2{
        font-size: 2rem;
        max-width: 450px;
    }
    .event-page-why-join-section-right p{
        font-size: 12px;
        max-width: 470px;
    }
    .event-page-why-join-section{
        display: flex;
        justify-content: space-evenly;
        padding: 2rem 3rem;
    }
    .event-page-why-join-section-left-icon img{
        width: 50px;
    }
    .event-page-why-join-section-left-icon{
        width: 40px;
        text-align: center;
    }
}

@media screen and (max-width:450px) {
    .event-page-landing-section h1{
        font-size: 2rem;
    }
    .event-page-landing-section{
        text-align: center;
    }
    .event-page-landing-section p{
        font-size: 14px;
    }
    .event-page-why-join-section-right h2{
        font-size: 1.5rem;
    }
    .event-page-why-join-section-right p{
        font-size: 10px;
    }
}