.social-media-section{
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background-color: black;
    color: white;
}
.social-media-sites{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.social-media-icon{
    width: 40px;
    height: 40px;
    color: white;
}
.social-media-icon:hover{
    color: var(--blue);
}