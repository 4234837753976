.volunteer-pdf-section{
    margin-top: 3rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap-reverse;
    padding: 2rem 3rem;
}
.volunteer-pdf-section-right{
    max-width: 600px;
}
.volunteer-pdf-section-right h2{
    margin-bottom: 2rem;
    position: relative;
}
.volunteer-pdf-section-right h2::before{
    content: "";
    position: absolute;
    bottom: -10px;
    width: 300px;
    height: 3px;
    background-color: var(--blue);
}

.volunteer-pdf-section-left{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.6rem;
}
.volunteer-pdf-section-left img{
    width: 200px;
}
.volunteer-pdf-section-left h5{
    max-width: 200px;
    text-align: center;
}
.volunteer-pdf-section-left .download-btn{
    border-radius: 20px;
}


@media screen and (max-width:950px) {
    .volunteer-pdf-section-right h2{
        font-size: 2rem;
    }
    .volunteer-pdf-section-right p{
        font-size: 10px;
        width: 500px;
    }
    .volunteer-pdf-section-right{
        width: 500px;
    }
    .volunteer-pdf-section-left img{
        width: 100px;
    }
    .volunteer-pdf-section-left .download-btn{
        font-size: 8px;
    }
    .volunteer-pdf-section-left h5{
        width: 130px;
    }
}

@media screen and (max-width:770px) {
    .volunteer-pdf-section-right{
        text-align: center;
    }
    .volunteer-pdf-section-right h2::before{
        display: none;
    }
}
@media screen and (max-width:600px) {
    .volunteer-pdf-section-right{
        width: 100%;
    }
    .volunteer-pdf-section-right p{
        font-size: 10px;
        width: auto;
    }
}
@media screen and (max-width:430px) {
    .volunteer-pdf-section-right h2{
        font-size: 1.5rem;
    }
    .volunteer-pdf-section-right p{
        font-size: 10px;
        width: auto;
    }
}