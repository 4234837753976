.previous-event-page-header{
    width: 100%;
    padding: 5rem 4rem;
    background: url("../../images/event\ landing\ section\ bg.png");
    background-size: cover;
    background-position: center;
    position: relative;
}
.previous-event-page-header::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 128, 0, 0.532);
}
.previous-event-page-header h1{
    position: relative;
    color: white;
}
.previous-event-page-events-section{
    margin-top: 3rem;
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-evenly;
    gap: 3rem;
    flex-wrap: wrap;
}

@media screen and (max-width:800px) {
    .previous-event-page-header h1{
       text-align: center;
    }
}
@media screen and (max-width:550px) {
    .previous-event-page-header h1{
       font-size: 3rem;
    }
}