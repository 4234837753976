.home-page-landing-section{
    width: 100%;
    height: 100vh;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    background: url("../../images/home\ page\ landing\ section\ bg.png");
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
    overflow: hidden;
}
.home-page-landing-section::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.395);
}
.home-page-landing-section-logo{
    width: 100px;
    position: relative;
}
.home-page-landing-section h3 , .home-page-landing-section h1 , .home-page-landing-section p{ 
    position: relative;
}

.home-page-landing-section p{
    max-width: 600px;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}
.drop-animation{
    margin-top: 40px;
    width: 30px;
    height: 50px;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 20px;
    position: relative;
}
.drop-animation::before{
    content: "";
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;
    animation: dropAnimation 2s linear infinite;
    transition: 0.6s linear;
}
@keyframes dropAnimation{
    0%{
        top: 20%;
        background-color: var(--blue);
    }
    50%{
        top: 80%;
        background-color: var(--yellow);
    }
    100%{
        top: 20%;
        background-color: var(--green);
    }
}
.home-page-landing-section span{
    position: absolute;
    transition: 0.6s linear;
    z-index: 100;
    clip-path: polygon(
    50% 15%,
    60% 5%,
    70% 15%,
    70% 30%,
    50% 95%,
    30% 30%,
    30% 15%,
    40% 5%
  );
}
.home-page-quality-section{
    background: url("../../images/home\ page\ quality\ section.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 3rem 3rem;
    color: white;
    margin-top: 3rem;
}
.quality-element{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
}
.quality-element img{
    width: 50px;
}
.quality-element h3{
  font-family: 'Kanit', sans-serif;
  letter-spacing: 5px;
}

@media screen and (max-width:550px) {
    .quality-element img{
        width: 30px;
    }
    .quality-element h3{
        font-size: 1rem;
    }
    .quality-element p{
        font-size: 8px;
    }
    .quality-element{
        text-align: center;
        max-width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width:700px) {
    .home-page-landing-section h1{
        font-size: 3rem;
    }
    .home-page-landing-section p{
        max-width: 500px;
    }
}
@media screen and (max-width:550px) {
    .home-page-landing-section{
        height: auto;
        min-height: 80vh;
        padding: 8rem 3rem;
    }
}
@media screen and (max-width:450px) {
    .home-page-landing-section h1{
        font-size: 2rem;
        text-align: center;
    }
    .home-page-landing-section h3{
        font-size: 1rem;
    }
    .home-page-landing-section p{
        max-width: 500px;
        font-size: 10px;
    }
    .home-page-landing-section{
        gap: 1rem;
    }
}