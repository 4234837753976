.previous-event-section{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 2rem;
}
.previous-event-section-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
    text-align: center;
}
.previous-event-section-top p{
    font-size: 14px;
    max-width: 700px;
    text-align: center;
}
.previous-event-section-top h2 span{
    color: var(--red);
}
.previous-event-section-bottom{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4rem;
    padding: 1rem 2rem;
    margin-top: 2rem;
    overflow-x: scroll;
}
.previous-event-section-bottom::-webkit-scrollbar{
    display: none;
}

@media screen and (max-width:650px) {
    .previous-event-section-top h2{
        font-size: 2rem;
    }
    .previous-event-section-top p{
        font-size: 12px;
        max-width: 450px;
    }
}
@media screen and (max-width:420px) {
    .previous-event-section-top h2{
        font-size: 1.5rem;
    }
    .previous-event-section-top p{
        font-size: 10px;
        max-width: 370px;
    }
}