.event-list-card{
    width: 400px;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1rem 1rem;
    border-radius: 8px;
    transition: 0.6s;
    cursor: pointer;
}
.event-list-card:hover{
    background-color: var(--green);
}
.event-list-card img{
    width: 100px;
    height: 70px;
    object-fit: cover;
    border-radius: 8px;
}
.event-list-card .event-summary{
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}
.event-list-card .event-summary p{
    font-size: 10px;
    max-width: 270px;
    text-align: left;
}

@media screen and (max-width:500px) {
    .event-list-card img{
        width: 70px;
        border-radius: 8px;
    }
    .event-list-card{
        width: 300px;
    }
}
@media screen and (max-width:420px) {
    .event-list-card img{
        width: 40px;
        border-radius: 8px;
    }
    .event-list-card{
        width: 270px;
    }
    .event-list-card .event-summary p{
        font-size: 8px;
        max-width: 240px;
        text-align: left;
    }
    .event-list-card .event-summary{
        display: flex;
        flex-direction: column;
        gap: 0rem;
    }
    
}