.login-register-page{
    width: 100%;
    padding: 5rem 3rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;
    background: url("../../images/loginPageBg.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    min-height: 100vh;
}
.login-register-page .btn-a{
    border-radius: 20px;
}
.login-register-page::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.563);
}
/* .login-form{
    cursor: url("../../images/heartCursor.png") , auto;
}
.register-form{
    cursor: url("../../images/cursor.png") , auto;
} */
.join-form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}
.join-form h3{
    color: white;
}
.join-form .input-field{
    background-color: transparent;
    color: white;
    border-bottom: 1px solid white;
    width: 300px;
}
.join-form .input-field::placeholder{
    color: white;
}
.field-row{
    display: flex;
    gap: 2rem;
}
.register-form .field-row .input-field{
    width: 200px;
}
input[type="number"]{
    color: red;
}

.divide-line{
    width: 2px;
    height: 60vh;
    background-color: white;
    border-radius: 20px;
    position: relative;
}

@media screen  and (max-width:940px){
    .divide-line{
        display: none;
    }
}