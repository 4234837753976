.admin-home-page{
    width: 100%;
    padding: 5rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.admin-home-page-first-row{
    width: 100%;
}
.admin-home-page-first-row-card{
    width: 320px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    border-radius: 8px;
    padding: 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.admin-home-page-first-row-card:nth-child(1) h2{
    color: var(--red);
}
.admin-home-page-first-row-card:nth-child(2) h2{
    color: var(--green);
}
.admin-home-page-first-row-card:nth-child(3) h2{
    color: var(--purple);
}
.admin-home-page-first-row-card p{
    font-size: 12px;
}


.admin-home-page-second-row-left-column{
    background-color: black;
    width: 100%;
    height: auto;
    border-radius: 8px;
    padding: 2rem 2rem;
}
.admin-home-page-second-row-left-column h3{
    color: white;
}
.admin-home-page-second-row-right-column{
    background-color: var(--purple);
    width: 40%;
    border-radius: 8px;
    padding: 2rem 2rem;
}
.admin-home-page-second-row-right-column h3{
    color: white;
}
.admin-home-page-second-row-left-column .verticle-scrollable-section , .admin-home-page-second-row-right-column .verticle-scrollable-section{
    width: 100%;
    background-color: white;
    height: 500px;
    margin-top: 2rem;
    border-radius: 8px;
    padding: 1.5rem 1.2rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.admin-home-page-second-row-right-column .verticle-scrollable-section{
   height: 400px;
}




.admin-home-page-third-row{
    width: 100%;
    background-color: var(--gray);
    border-radius: 8px;
    padding: 2rem 2rem;
}
.admin-home-page-third-row .verticle-scrollable-section{
    background-color: white;
    height: 400px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    padding: 2rem 2rem;
    overflow-y: scroll;
    margin-top: 2rem;
    border-radius: 8px;
}

@media screen and (max-width : 900px) {
    .admin-home-page-first-row-card{
        width: 250px;
        height: 150px;
    }
}

@media screen and (max-width : 700px) {
    .admin-home-page-third-row .verticle-scrollable-section{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 2rem;
    }
    .admin-home-page-first-row-card{
        width: 170px;
        height: 150px;
    }
    .admin-home-page-first-row{
        gap: 1rem;
    }
}

@media screen and (max-width : 550px) {
    .admin-home-page-first-row-card{
        width: 130px;
        height: 120px;
    }
    .admin-home-page-first-row-card h2{
        font-size: 1.5rem;
    }
    .admin-home-page-first-row-card p{
        font-size: 10px;
    }
}

