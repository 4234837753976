.admin-navbar{
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0%;
    background-color: var(--red);
    color: white;
    z-index: 1000;
}
.admin-navbar-left{
    display: flex;
    gap: 2rem;
    align-items: center;
}
.admin-navbar-left img{
    width: 30px;
}
.admin-navbar-right{
    cursor: pointer;
}


.sliding-admin-navbar{
    position: absolute;
    right: 0%;
    top: 0%;
    width: 300px;
    min-height: 100vh;
    height: 100%;
    background-color: var(--red);
    z-index: 999;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    transition: 0.8s;
}
.sliding-admin-navbar h4{
    color: white;
}
.sliding-admin-navbar button{
    border: none;
    background-color: var(--green);
    cursor: pointer;
}
.sliding-admin-navbar .close-icon{
    cursor: pointer;
    min-width: 30px;
    height: 30px;
    position: absolute;
    left: 5%;
    top: 5%;
}

@media screen and (max-width:450px) {
    .admin-navbar-left h4{
        font-size: 10px;
    }
}