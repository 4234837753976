.contact-page{
    width: 100%;
    padding: 5rem 3rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;
    background: url("../../images/contact-us-page-background.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
}
.contact-page::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #295e8fa4;
}
.contact-page-left{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.contact-form{
    padding: 2rem 2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.contact-form .input-field{
    width: 300px;
}
.contact-form .text-box textarea{
    width: 300px;
    height: 100px;
    outline: none;
    padding: 0.5rem 0.3rem;
    border: 1px solid #295D8F;
    margin-top: 0.5rem;
    resize: none;
}
.contact-form .download-btn{
    background-color: var(--purple);
    border-radius: 20px;
}




.contact-page-right{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}
.contact-page-right-row{
    display: flex;
    gap: 1.5rem;
    align-items: center;
}
.contact-page-right-row-left{
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-page-right-row-right{
    color: white;
    max-width: 250px;
}
.contact-page-right-row-right p{
    font-size: 14px;
    margin-top: 0.5rem;
}
.contact-page-right-row-left-icon{
    color: #295D8F;
}

@media screen and (max-width:440px) {
    .contact-page-right-row-right{
        color: white;
        max-width: 200px;
    }
    .contact-page-right-row-left{
        width: 30px;
        height: 30px;
    }
    .contact-page-right-row-left-icon{
        max-width: 15px;
        max-height: 15px;
    }
    .contact-form .input-field{
        width: 250px;
    }
    .contact-form .text-box textarea{
        width: 250px;
        height: 100px;
        outline: none;
        padding: 0.5rem 0.3rem;
        border: 1px solid #295D8F;
        margin-top: 0.5rem;
        resize: none;
    }
}