.upcoming-events-section{
    margin-top: 1rem;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upcoming-events-section p{
    max-width: 700px;
    text-align: center;
    margin-top: 15px;
    font-size: 12px;
}
.events-section{
    margin-top: 2rem;
    flex-wrap: wrap-reverse;
}
.event-list-box{
    width: 450px;
    height: auto;
    max-height: 500px;
    padding: 1.5rem 1.5rem;
    background-color: var(--gray);
    border-radius: 8px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.event-list-box::-webkit-scrollbar{
    display: none;
}
.particular-event-box{
    width: 350px;
    padding: 1.5rem 1.5rem;
    background-color: var(--gray);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.particular-event-box img{
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.particular-event-box p{
    text-align: left;
}


@media screen and (max-width:570px) {
    .upcoming-events-section h2{
        font-size: 2rem;
    }
    .event-list-box{
        width: auto;
    }
    .events-section{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width:470px) {
    .particular-event-box{
        width: 300px;
    }
}
@media screen and (max-width:420px) {
    .events-section{
        width: 350px;
        overflow: hidden;
    }
}