.volunteer-guideline-landing-section{
    width: 100%;
    padding: 5rem 4rem;
    background: url("../../images/volunteer\ page\ bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: space-between;
    position: relative;
    align-items: center;
    height: 100vh;
    color: white;
}
.volunteer-guideline-landing-section::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #077918a0;
}
.volunteer-guideline-landing-section-left , .volunteer-guideline-landing-section-right{
    position: relative;
}
.volunteer-guideline-landing-section-left p{
    max-width: 600px;
    z-index: 999;
}
.volunteer-guideline-landing-section-right img{
    width: 400px;
}
.volunteer-guideline-landing-section-right{
    position: absolute;
    right: 5%;
    bottom: 0%;
}

.volunteer-guideline-responsibility-section{
    margin-top: 3rem;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.responsibility-section-top h2{
    position: relative;
    max-width: 600px;
}
.responsibility-section-top h2::before{
    content: "";
    position: absolute;
    bottom: -15px;
    width: 400px;
    height: 3px;
    background-color: var(--blue);
}


.responsibility-section-between{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;
    padding: 2rem;
}
.responsibility-section-between-right p{
    max-width: 600px;
    margin-top: 1rem;
}
.responsibility-section-between-left{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
}
.responsibility-section-between-left-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
}
.responsibility-section-between-left-item img{
    width: 100px;
}



.responsibility-section-bottom{
    display: flex;
    justify-content: center;
    align-items: center;
}
.responsibility-section-bottom p{
    font-size: 14px;
    max-width: 700px;
    text-align: center;
}


.volunteer-guideline-love-section{
    width: 100%;
    padding: 4rem 4rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: url("../../images/volunteer\ page\ love\ section.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: white;
    position: relative;
    margin-top: 3rem;
}

.volunteer-guideline-love-section::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.656);
}
.volunteer-guideline-love-section h2{
    max-width: 600px;
    font-family: 'Kanit', sans-serif;
    text-transform: uppercase;
    position: relative;
}
.volunteer-guideline-love-section img{
    position: relative;
    width: 300px;
}


@media screen and (max-width:880px) {
    .volunteer-guideline-love-section h2{
        font-size: 2rem;
    }
    .volunteer-guideline-love-section img{
        width: 250px;
    }
    .volunteer-guideline-landing-section-left{
        text-align: center;
    }
    .volunteer-guideline-landing-section-right img{
        display: none;
    }
}
@media screen and (max-width:680px) {
    .volunteer-guideline-love-section h2{
        font-size: 1.5rem;
    }
    .volunteer-guideline-love-section img{
        width: 170px;
    }
}
@media screen and (max-width:500px) {
    .volunteer-guideline-love-section h2{
        font-size: 0.8rem;
    }
    .volunteer-guideline-love-section img{
        width: 80px;
    }
}

@media screen and (max-width:1100px) {
    .volunteer-guideline-landing-section-left h1{
        font-size: 3.5rem;
    }
    .volunteer-guideline-landing-section-right img{
        width: 200px;
    }
}
@media screen and (max-width:450px) {
    .volunteer-guideline-landing-section-left h1{
        font-size: 2rem;
    }
    .volunteer-guideline-landing-section-left p{
        font-size: 10px;
    }
    .volunteer-guideline-landing-section{
        height: auto;
        min-height: 70vh;
    }
}
@media screen and (max-width:750px) {
    .responsibility-section-top h2{
        font-size: 2rem;
    }
    .responsibility-section-between-left-item img{
        width: 80px;
    }
    .responsibility-section-between-right{
        text-align: center;
    }
}
@media screen and (max-width:600px) {
    .responsibility-section-between-left-item img{
        width: 60px;
    }
    .responsibility-section-between-left-item h5{
        width: 80px;
        text-align: center;
    }
    .responsibility-section-between-left{
        gap: 4rem;
    }
}
@media screen and (max-width:500px) {
    .responsibility-section-top h2::before{
        width: 200px;
    }
    .responsibility-section-between-right p{
        font-size: 12px;
    }
    
}
@media screen and (max-width:400px) {
    .responsibility-section-top h2{
        font-size: 1.5rem;
    }
    .volunteer-guideline-responsibility-section{
        padding: 2rem 1rem;
    }
}