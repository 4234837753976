.contribute-section{
    margin-top: 3rem;
    padding: 3rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contribute-section p{
    max-width: 700px;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
}
.contribute-section img{
    width: 500px;
    margin-top: 2rem;
}

@media screen and (max-width:650px) {
    .contribute-section img{
        width: 350px;
    }
    .contribute-section{
        padding: 3rem 4rem;
        text-align: center;
    }
}
@media screen and (max-width:450px) {
    .contribute-section img{
        width: 200px;
    }
    .contribute-section h2{
        font-size: 2rem;
    }
    .contribute-section p{
        font-size: 8px;
    }
}