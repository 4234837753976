.admin-footer{
    background-color: black;
    color: white;
    text-align: center;
    padding: 1.5rem 2rem;
}
.admin-footer span a{
    color: var(--yellow);
}
@media screen and (max-width:450px) {
    .admin-footer h5{
        font-size: 10px;
    }
}