@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');
*{
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
html{
  display: flex;
  justify-content: center;
  scroll-behavior: smooth;
}
body{
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  max-width: 1600px;
}
:root{
  --blue : #38B6FF;
  --green : #36AB9C;
  --purple : #683998;
  --red : #ED0E64;
  --yellow : #F6AC0D;
  --gray : #F5F5F6;
}
h1{
  font-size: 5rem;
  font-family: 'Kanit', sans-serif;
}
h2{
  font-size: 3rem;
}
h3{
  font-size: 1.5rem;
}
.common-flex-section{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  padding: 2rem 2rem;
}
.common-underline{
  width: 350px;
  height: 10px;
  background-color: var(--blue);
}
a{
  text-decoration: none;
}
.btn-a{
  padding: 0.5rem 1rem;
  background-color: var(--purple);
  color: white;
  border-radius: 20px;
  text-align: center;
}
.download-btn{
  padding: 0.7rem 1rem;
  background-color: var(--purple);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 20px;
}
.input-field{
  border: none;
  outline: none;
  border-bottom: 1px solid #295D8F;
  padding: 0.5rem 0.3rem;
}

.delete-screen{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0%;
  z-index: 999;
}
.delete-msg-box{
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: var(--blue);
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: white;
  font-size: 20px;
}
.confirm-btn{
  background-color: var(--purple);
}
.delete-msg-box .btn-a{
  cursor: pointer;
  font-size: 12px;
}
.delete-screen .delete-msg-box .common-flex-section{
  padding: 1rem 1rem;
  margin: none;
}