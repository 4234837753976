.testimonial-card{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    padding: 2.5rem 1.5rem;
    min-width: 250px;
    height: 300px;
    position: relative;
}
.testimonial-card .frame{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.testimonial-card .user-img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}
.testimonial-card p{
    font-size: 10px;
    text-align: center;
    max-width: 150px;
}