.admin-upcoming-event-page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3rem 3rem;
}

.admin-upcoming-event-page-top{
  padding: 3rem 3rem;
  margin-top: 5rem;
  margin-bottom: 3rem;
  color: white;
  border-radius: 8px;
  width: 90%;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.admin-upcoming-event-page-top-left p{
  font-size: 12px;
  max-width: 400px;
  margin-top: 1rem;
}

.admin-upcoming-event-page-top-right{
  width: 120px;
  height: 120px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.admin-upcoming-event-page-top-right .create-icon{
  color: var(--green);
  width: 40px;
  height: 40px;
}
.admin-upcoming-event-page-top-right p{
  color: var(--green);
  font-size: 8px;
  font-weight: bolder;
}

.admin-upcoming-event-page-bottom{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  padding: 1rem 1rem;
}


.create-event-screen{
  position: fixed;
  top: 6%;
  left: 0%;
  width: 100%;
  height: 100%;
  /* background-color: #23a6d5; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-event-form{
  background-color: white;
  padding: 2rem 2rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.row{
  display: flex;
  gap: 3rem;
  align-items: center;
  flex-wrap: wrap;
}
.upload-image{
  width: 170px;
  height: 170px;
  background-color: #23a6d5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 2rem 2rem;
  border-radius: 8px;
  color: white;
  text-align: center;
  position: relative;
}
.upload-image input[type="file"]{
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.name-and-date-box{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.name-and-date-box .input-field{
  width: 350px;
}
.create-event-form textarea{
  width: 50%;
  height: 120px;
  outline: none;
  padding: 0.5rem 0.3rem;
  border: 1px solid #295D8F;
  margin-top: 0.5rem;
  resize: none;
  border-radius: 5px;
}
.create-event-form .row .summary{
  width: 40%;
  height: 70px;
  outline: none;
  padding: 0.5rem 0.3rem;
  border: 1px solid #295D8F;
  margin-top: 0.5rem;
  resize: none;
  border-radius: 5px;
}

.create-event-form .close-icon{
  position: absolute;
  top: 3%;
  right: 3%;
  cursor: pointer;
}


@media screen and (max-width:750px) {
  .admin-upcoming-event-page-top{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    text-align: center;
  }
}

@media screen and (max-width:550px) {
  .upload-image{
    width: 80px;
    height: 80px;
  }
  .upload-image p{
    font-size: 8px;
  }
  .name-and-date-box .input-field{
    width: 200px;
  }
}

@media screen and (max-width:450px){
  .admin-upcoming-event-page-top-left h3{
    font-size: 18px;
  }
  .admin-upcoming-event-page-top-left p{
    font-size: 8px;
  }
  .row .description-box{
    width: 150px;
  }
  .row .summary{
    width: 150px;
    height: 100px;
  }
}

@media screen and (max-width:400px) {
  .name-and-date-box .input-field{
    width: 120px;
    font-size: 10px;
  }
  .row .description-box{
    width: 100px;
    height: 100px;
    font-size: 8px;
  }
  .row .summary{
    width: 100px;
    min-height: 100px;
    font-size: 8px;
  }
}