.footer{
    margin-top: 5rem;
    background-color: black;
    color: white;
    padding: 3rem 2rem;
}
.footer-top{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 2rem;
}
.column{
    max-width: 400px;
}
#column1{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
#column1 img{
    width: 100px;
}
#column2{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
#column2 a{
    color: white;
}
#column3{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
#column3 p{
    font-size: 8px;
    text-align: right;
    max-width: 370px;
}
#column3 h4{
    text-align: right;
}
#column3 a{
    width: 180px;
    align-self: end;
    color: white;
    background-color: var(--purple);
    border-radius: 20px;
    padding: 0.6rem 0.8rem;
    text-align: center;
    font-size: 14px;
}
.footer-bottom{
    text-align: center;
    margin-top: 3rem;
}
.footer-bottom h5 span a{
    color: var(--yellow);
}