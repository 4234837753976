.common-card{
    width: 250px;
    height: 300px;
    border-radius: 8px;
    background-color: var(--gray);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
    cursor: pointer;
    margin-top: 3rem;
    transition: 0.6s;
    color: black;
}
.common-card:hover , .common-card:nth-child(2){
    background-color: var(--blue);
    color: white;
}
.common-card:nth-child(2):hover{
    background-color: var(--gray);
    color: black;
}
.common-card img{
    width: 60px;
}
.common-card p{
    font-size: 10px;
}
.common-card:hover > img , .common-card:nth-child(2) img{
    filter: invert(100%);
}
.common-card:nth-child(2):hover img{
    filter: invert(0%);
}


@media screen and (max-width:800px) {
    .common-card{
        width: 180px;
        height: 200px;
    }
    .common-card img{
        width: 30px;
    }
    .common-card h4{
        font-size: 10px;
    }
    .common-card p{
        font-size: 8px;
    }
}