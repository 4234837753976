.about-us-section{
    margin-top: 3rem;
    padding: 5rem 4rem;
    justify-content: space-between;
    background: url("../../images/about\ us\ section\ bg.png");
    background-size: cover;
    background-position: center;
    position: relative;
    /* cursor: url("../../images/cursor.png") , auto; */
}
.about-us-section .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
}
.about-us-section .overlay span{
    position: absolute;
    transition: 0.6s linear;
    z-index: 100;
    clip-path: polygon(
    50% 15%,
    60% 5%,
    70% 15%,
    70% 30%,
    50% 95%,
    30% 30%,
    30% 15%,
    40% 5%
  );
}
.about-us-section::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.83);
}
.about-us-section .right-side{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.about-us-section .right-side img{
    width: 300px;
}
.about-us-section .left-side{
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    text-align: right;
    color: white;
    position: relative;
}
.about-us-section .left-side p{
    font-size: 10px;
    max-width: 500px;
}
.about-us-section .hanging-heart{
    position: absolute;
    width: 100px;
    top: 0%;
    left: 5%;
}
.hanging-heart-2{
    position: absolute;
    width: 100px;
    top: 0%;
    left: 10%;
}


@media screen and (max-width:999px) {
    .about-us-section .right-side img{
        width: 200px;
    }
    .about-us-section .left-side p{
        font-size: 10px;
        max-width: 400px;
    }
    .about-us-section .left-side h1{
        font-size: 4rem;
    }
}
@media screen and (max-width:799px) {
    .about-us-section{
        flex-direction: column-reverse;
    }
    .about-us-section .right-side img{
        width: 250px;
    }
    .about-us-section .left-side{
        text-align: center;
    }
    .about-us-section .left-side h1{
        font-size: 4rem;
    }
}
@media screen and (max-width:550px) {
   .about-us-section .left-side h1{
    font-size: 2rem;
   }
   .about-us-section .left-side h3{
     font-size: 1rem;
   }
   .about-us-section .left-side p{
    font-size: 8px;
   }
   .about-us-section .right-side img{
    width: 180px;
   }
}