.partner-section{
    width: 100%;
    padding: 3rem 3rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;
    background: url("../../images/partner\ section\ bg.png");
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
}
.partner-section::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.partner-section-right{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    max-width: 600px;
}
.partner-section-right h1{
    text-transform: uppercase;
}
.partner-section-right p{
    max-width: 500px;
    font-size: 14px;
}
.partner-section-left{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 4rem;
    position: relative;
}
.partner-section-left-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
}
.partner-section-left-item img{
    width: 100px;
}


@media screen and (max-width:999px) {
    .partner-section-left-item img{
        width: 70px;
    }
    
    .partner-section h1{
        font-size: 3rem;
    }
    .partner-section p{
        font-size: 12px;
    }.partner-section-right{
        width: 400px;
    }
}
@media screen and (max-width:820px) {
    .partner-section{
        text-align: center;
    }
    .partner-section-right{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width:450px) {
    .partner-section h1{
        font-size: 1.5rem;
    }
   .partner-section p{
    font-size: 10px;
   }
   .partner-section img{
    width: 40px;
   }
   .partner-section-left{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    position: relative;
}
}