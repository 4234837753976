.navbar{
    position: fixed;
    top: 0%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2.3rem;
    backdrop-filter: blur(6px);
    background-color: rgba(83, 83, 83, 0.546);
    z-index: 999;
    transition: 0.2s;
}
.navbar .row{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.navbar .row h4{
    color: white;
}
.navbar img{
    width: 40px;
}
.nav-items{
    display: flex;
    gap: 2rem;
    align-items: center;
}
.nav-items a{
    color: white;
    position: relative;
}
.nav-items .btn-a{
    background-color: var(--purple);
    width: 130px;
}
.navbar .menu-icon{
    color: white;
    cursor: pointer;
    display: none;
}
.nav-items .logout-btn{
    background-color: var(--red);
}
.responsive-navbar{
    position: fixed;
    top: 0%;
    right: 0%;
    width: 80vw;
    height: 100vh;
    background-color: var(--blue);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    color: white;
    z-index: 999;
}
.responsive-navbar .item{
    color: white;
}
.responsive-navbar .close-icon{
    cursor: pointer;
    position: absolute;
    top: 5%;
    right: 5%;
}
.responsive-navbar .btn-a{
    background-color: white;
    width: 130px;
    color: var(--blue);
}

@media screen and (max-width:850px) {
    .navbar .menu-icon{
        display: block;
    }
    .navbar .nav-items{
        display: none;
    }
}