.testimonial-section{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.testimonial-section h3 span{
    color: var(--red);
}
.testimonial-section p{
    max-width: 700px;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
}
.testimonial-scroll-section{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1rem 2rem;
    margin-top: 2rem;
    overflow-x: scroll;
}
.testimonial-scroll-section::-webkit-scrollbar{
    display: none;
}

@media screen and (max-width:400px) {
    .testimonial-section p{
        max-width: 360px;
        font-size: 8px;
    }
}