.registered-user-list-card{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    transition: 0.5s;
    padding: 1rem 1rem;
    border-radius: 8px;
    border-bottom: 1px solid var(--green);
}
.registered-user-list-card:hover{
    background-color: var(--gray);
}
.registered-user-list-card-left,.registered-user-list-card-right{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

@media screen and (max-width:600px) {
    .registered-user-list-card-left h4{
        font-size: 14px;
    }
    .registered-user-list-card-right p{
        font-size: 10px;
    }
}
@media screen and (max-width:450px) {
    .registered-user-list-card-left h4{
        font-size: 12px;
    }
}