.user-query-card{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background-color: var(--gray);
    padding: 1rem 1rem;
    border-radius: 8px;
    transition: 0.5s;
}
.user-query-card:hover{
    background-color: var(--yellow);
}
.user-query-card-left{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}
.user-query-card-right p{
    font-size: 10px;
    max-width: 400px;
}
.user-query-card button{
    padding: 0.6rem 0.4rem;
    border: none;
    max-width: 250px;
    cursor: pointer;
}

@media screen and (max-width:700px) {
    .user-query-card{
        padding: 2rem 1rem;
    }
}
@media screen and (max-width:550px) {
    .user-query-card button{
        max-width: 200px;
    }
}