.previous-event-card{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    max-width: 300px;
    -webkit-user-select: none;
}
.previous-event-card img{
    width: 300px;
    height: 170px;
    object-fit: cover;
}
.previous-event-card h3{
    color: var(--red);
}
.previous-event-card p{
    font-size: 10px;
    max-width: 270px;
}

@media screen and (max-width : 550px) {
    .previous-event-card img{
        width: 200px;
        height: 100px;
        object-fit: cover;
    }
    .previous-event-card h3{
        font-size: 1rem;
    }
    .previous-event-card p{
        font-size: 8px;
    }
}