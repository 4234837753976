.admin-event-list-card{
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 2rem;
    padding: 1rem 1rem;
    transition: 0.3s;
    border-radius: 8px;
}
.admin-event-list-card:hover{
    background-color: var(--green);
}
.admin-event-list-card-left img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
